<template>
  <div>
    <b-dropdown
      id="dropdown-1"
      :text="category.Description"
      block
      variant="primary"
      class="m-md-2 mb-3"
      menu-class="w-100"
    >
      <b-dropdown-item
        v-for="subCategory in subCategories"
        :key="subCategory.CategoryID"
        class="mb-2"
      >
        <router-link :to="`/${$i18n.locale}/shop/${subCategory.CategoryID}`">
          {{ subCategory.Description }}
        </router-link>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'CategoriesCategory',
  props: {
    category: {
      type: Object,
      required: true
    },
    categories: {
      type: Array,
      required: true
    }
  },
  computed: {
    subCategories() {
      return this.categories.filter(
        category => category.ParentCategoryID === this.category.CategoryID
      )
    }
  }
}
</script>

<style scoped></style>
